import React, { useEffect } from 'react'
import { Spring, config } from 'react-spring/renderprops.cjs'
import { navigate } from 'gatsby'

import { Section, Button, Heading, Body, CircularLoader } from 'components'
import { useMagic, useUserStore } from 'stores'

export default function Thankyou() {
  const { setUserBooks, loading } = useUserStore()
  const { user } = useMagic()

  useEffect(() => {
    setUserBooks(user, true)
  }, [])

  return (
    <Section
      display="flex"
      justifyContent="center"
      alignItems="center"
      minHeight="80vh"
      data-testid="thankyou-page"
    >
      <div className="container flex flex-column justify-center align-center">
        <Spring
          config={config.wobbly}
          from={{
            transform: 'scale(0)',
            marginBottom: '1rem',
            fontSize: '3rem'
          }}
          to={{
            transform: 'scale(1)',
            marginBottom: '1rem',
            fontSize: '3rem'
          }}
        >
          {style => (
            <span style={style} role="img">
              🎉
            </span>
          )}
        </Spring>
        <Heading mb={3}>Thank You!</Heading>
        <Body mb={5}>Your book is now available in your dashboard</Body>
        <Button
          data-testid="thankyou-btn"
          disabled={loading}
          onClick={() => {
            navigate('/app/dashboard')
          }}
        >
          Go to Dashboard
          {loading && <CircularLoader data-testid="circle-loader" />}
        </Button>
      </div>
    </Section>
  )
}
